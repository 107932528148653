<template>
  <!-- 投注紀錄 -->
  <div
    id="wager"
    v-scroll.self="onScroll"
    class="pt-56 vh-100 overflow-y-auto"
    :class="currentDevice === 'ios' ? 'pb-72' : 'pb-10'"
  >
    <ToolbarTemplate3>
      <!-- 篩選 vendor code -->
      <v-btn
        slot="btn"
        text
        color="icon"
        @click="dialog = true"
      >
        <!-- FILTER ICON -->
        <Icon
          class="white--text mr-1"
          data="@icon/template3-filter.svg"
          width="24"
          height="24"
        />
        <span class="white--text">
          {{ activeVendor }}
        </span>
      </v-btn>
    </ToolbarTemplate3>

    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="z-index-1 fixed bg"
        >
          <v-container class="pa-0">
            <!-- 快選日期 -->
            <v-row class="mt-1 px-2 pb-2">
              <v-col
                v-for="(item, index) in dataBtnText"
                :key="index"
                class="rounded-lg mx-1 caption px-0 py-2 text-center d-flex align-center justify-center cursor-pointer"
                :class="[
                  theme === 'dark' ? 'black' : 'white',
                  { 'subtitle--text': dateBtn !== index }

                ]"
                :style="tabClr(index)"
                @click="changeData(...Object.keys(item), index)"
              >
                {{ item[Object.keys(item)] }}
              </v-col>

              <!-- 自定義日期 -->
              <v-col
                class="rounded-lg mx-1 caption px-0 py-2 text-center d-flex align-center justify-center"
                :class="[
                  theme === 'dark' ? 'black' : 'white',
                  { 'subtitle--text': dateBtn !== 4 }
                ]"
                :style="tabClr(4)"
                @click="customDate"
              >
                {{ $t('myCenter.customize') }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row
        class="px-1"
        :class="[ dynamicStyleByClientLang ]"
      >
        <v-col
          cols="12"
          class="pb-0"
        >
          <CustomizeTemplate3
            v-if="dateBtn === 4"
            @get-data="getWagerRecord"
            @reset-config="resetNScroll"
          />

          <!-- 提醒說明文字 -->
          <p class="caption mb-0 pb-0 comment--text">
            {{ $t('myCenter.wagerDelayMessage') }}
          </p>

          <!-- 有效投注總額 -->
          <p class="d-flex align-center">
            <!-- 金錢符號 -->
            <Icon
              data="@icon/betValid.svg"
              width="16"
              height="16"
              class="icon--text"
            />
            <!-- 投注總額 金額 -->
            <span class="primary--text subtitle-2 font-weight-medium mx-2">
              {{ validBetTotal }}
            </span>
            <span class="caption subtitle--text">
              ({{ $t('global.validBetTotal') }})
            </span>
          </p>
        </v-col>

        <!-- 如果無資料 -->
        <template v-if="resData.length === 0">
          <v-col
            cols="12"
            class="mt-80px"
          >
            <NodataTemplate3 :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <!-- 如果有資料 -->
        <template v-else>
          <v-col
            cols="12"
            class="pt-0"
          >
            <div
              v-for="vendor in resData"
              :key="vendor._id"
              class="card1 rounded-lg my-2 record caption"
            >
              <!-- titile -->
              <div class="record__title rounded-t-lg pl-4 title--text py-1">
                <span>
                  {{ vendor.vendor }}
                </span>

                <span>
                  {{ vendor.game }}
                </span>
              </div>

              <!-- content -->
              <div class="pl-4 py-3 p-relative">
                <!-- 單號 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.no') }}
                  </span>
                  <span
                    :id="'a' + vendor.parent_bet_id"
                    class="ml-4"
                  >
                    {{ vendor.parent_bet_id }}
                  </span>

                  <!-- copy BTM -->
                  <v-btn
                    icon
                    height="24"
                    width="24"
                    @click="copyNumber('a' + vendor.parent_bet_id)"
                  >
                    <Icon
                      data="@icon/copy.svg"
                      width="16"
                      height="16"
                      class="primary--text"
                    />
                  </v-btn>
                </div>

                <!-- 投注 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.wager') }}
                  </span>
                  <span class="ml-4">
                    {{ vendor.bet_amount }}
                  </span>
                </div>

                <!-- 派彩 -->
                <div class="py-1">
                  <span class="subtitle--text">
                    {{ $t('myCenter.payoff') }}
                  </span>
                  <span class="ml-4">
                    {{ vendor.win_amount }}
                  </span>
                </div>

                <!-- 有效投注 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.validWager') }}
                  </span>
                  <span class="ml-4">
                    {{ vendor.translate }}
                  </span>
                </div>

                <!-- 日期 -->
                <div class="pt-1">
                  <span class="subtitle--text">
                    <Icon
                      data="@icon/date.svg"
                      width="13"
                      height="13"
                      class="subtitle--text"
                    />
                  </span>
                  <span class="ml-3 subtitle--text">
                    {{ $day(vendor.created_at).format('YYYY/MM/DD HH:mm') }}
                  </span>
                </div>

                <!-- 狀態 -->
                <v-btn
                  outlined
                  height="24"
                  width="61"
                  class="vendor__status p-absolute caption"
                  :color="vendor.status === 'Settled' ? 'success' : 'danger'"
                >
                  {{ $t(`myCenter.${vendor.status}`) }}
                </v-btn>
              </div>
            </div>
          </v-col>

          <!--  Bottom LOADING  -->
          <v-col
            v-show="scrollBottom.progress"
            cols="12"
            class="text-center pb-0"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- 選擇平台 -->
    <Select
      :status="dialog"
      :height="344"
      :width="280"
    >
      <v-row
        slot="title"
        class="p-sticky top-0 bg"
      >
        <!-- 選擇平台 -->
        <v-col
          cols="12"
          class="text-h6 title--text px-6 py-2 p-sticky text-center primary"
        >
          {{ $t('myCenter.chooseBrand') }}
        </v-col>
      </v-row>

      <v-row slot="list">
        <v-col
          v-for="(item, index) in vendorList"
          :key="index"
          cols="12"
          class="text-center"
          :class="[
            'px-6',
            Object.keys(item)[0] === activeVendor ? 'secondary' : 'bg',
            'rounded'
          ]"
          @click="selectVendor(Object.keys(item)[0])"
        >
          {{ Object.keys(item)[0] }}
        </v-col>
      </v-row>
    </Select>
  </div>
</template>

<script>
import wagerMixin from '../mixins/wagerMixin'

export default {
  mixins: [wagerMixin],
}
</script>

<style lang="scss" scoped>
.vendor__status {
	right: 8px;
	bottom: 8px;
}

.top-0 {
	top: 0;
}
.record__title{
  background: linear-gradient(270deg, #BE5A4F -6.94%, #C03111 100%);
}
</style>
