import Nodata from '@/components/base/Nodata.vue'
import NodataTemplate3 from '@/components/base/Nodata-template3.vue'
import Customize from '../CustomizeDate'
import CustomizeDateTemplate3 from '../CustomizeDateTemplate3'

import Select from '@/components/dialog/Select'
import SelectTemplate3 from '@/components/dialog/SelectTemplate3'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'

import { copyNumber } from '@/utils/copy'
import { timezone } from '@/utils/day'
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { isScrollToBottom, scrollBottomFunc } from '@/utils/scrollToBottom'

export default {
  components: {
    Nodata,
    NodataTemplate3,
    Select,
    Customize,
    CustomizeDateTemplate3,
    SelectTemplate3,
    ToolbarTemplate3,
  },

  data: () => ({
    tab: 0,
    dateBtn: 0,

    form: {
      type: '', // 類型。bonus:紅利,recommend:推薦禮金,event:活動獎品
      sub_type: '', // 子類別，僅 type === bonus 適用
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '',
      page: 1,
    },

    recordData: [],

    subTypeList: {
      active: '',
      text: [],
      code: [],
    },

    dialog: {
      status: false,
    },

    scrollBottom: {
      status: true,
      ing: false,
      progress: true,
    },

    lastPage: null,
  }),

  computed: {
    ...mapState({
      // 顯示 活動獎品
      showPrizeBonus: state => state.websiteData.func_switch.redeem_event,
    }),

    ...mapGetters(['clientLang', 'theme']),

    /**
     * tab 顯示文字
     * @date 2021-07-21
     */
    tabText() {
      return [
        this.$t('global.prizeBonus'),
        this.$t('global.bonus'),
        this.$t('global.recommendBonus'),
      ]
    },

    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    dynamicStyleByClientLang() {
      const widthAry = ['vi-VN', 'en', 'ph']
      const width = widthAry.includes(this.clientLang)
      return width ? 'pt-120' : 'pt-100'
    },

    secondaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.secondary
    },
    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },

    dynamicDateTabBorderColor() {
      if (this.theme === 'dark') return this.secondaryClr
      return this.primaryClr
    },
  },

  watch: {
    tab() {
      this.changeData(0, 0)
      this.subTypeList.active = this.$t('global.all')
    },

    'subTypeList.active'(newText) {
      this.form.sub_type = this.subTypeList.code[
        this.subTypeList.text.indexOf(newText)
      ]
      this.form.page = 1
      this.main(this.tab)
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.prize') + this.$t('global.record'))
    this.form.timezone = timezone()
    this.subTypeList.active = this.$t('global.all')
    this.setDate(0)
    // 如果不顯示「活動獎品」 tab
    if (!this.showPrizeBonus) this.tab = 1
  },

  methods: {
    ...mapActions([
      'get_redeem_record',
      'set_toolbar_title',
      'get_bonus_subtype',
      'show_alert',
    ]),

    /**
     * 功能入口，決定執行的 api
     * @date 2021-03-17
     * @param {any} nowIndex tab 索引，判斷執行的功能
     */
    main(nowIndex) {
      switch (nowIndex) {
        case 0:
          this.getRedeemLog('event')
          break

        case 1:
          this.getRedeemLog('bonus', this.form.sub_type)
          if (this.subTypeList.code.length === 0) this.getSubtypeData()
          break

        case 2:
          this.getRedeemLog('recommend')
          break

        default:
          break
      }
    },

    /**
     * 設置時間 (起始時間,結束時間)
     * @date 2021-03-17
     * @param {number}} dayNum 與今天相隔的天數
     */
    setDate(dayNum) {
      const today = this.$day().format('YYYY-MM-DD') // 今天日期
      const beforeDay = this.$day()
        .subtract(dayNum, 'day')
        .format('YYYY-MM-DD')

      // 如果為昨日
      if (dayNum === '1') {
        this.form.trans_at = `${beforeDay},${beforeDay}`
        return false
      }

      this.form.trans_at = `${beforeDay},${today}`
    },

    /**
     * 取得紀錄 (成功)
     * @date 2021-06-07
     * @param {object} data 紀錄資料
     */
    getRedeemLogSuccess({ logs }) {
      const resData = logs.data
      this.lastPage = logs.last_page

      // 第一次取資料
      if (this.form.page === 1) {
        this.recordData = []
        return (this.recordData = resData)
      }

      // 無資料
      if (resData.length === 0) {
        this.scrollBottom.status = false
        this.scrollBottom.progress = false
        this.form.page = this.form.page - 1
        return (this.scrollBottom.ing = false)
      }

      // 如果是滾動加載
      setTimeout(() => {
        this.recordData = [...this.recordData, ...resData]
        this.scrollBottom.progress = false
      }, 800)
    },

    /**
     * 取得兌獎紀錄
     * @date 2021-03-19
     * @param {string}} type 兌獎類型 ex: 活動獎品 / 紅利 / 推薦禮金
     * @param {string} subtype ='all' 紅利類型的子項目
     */
    async getRedeemLog(type, subtype = 'all') {
      this.form.type = type
      this.form.sub_type = subtype
      const res = await this.get_redeem_record(this.form)
      resStatus(res, this.getRedeemLogSuccess)
    },

    /**
     * 快捷日期選擇功能
     * @date 2021-03-17
     * @param {number} dayNum 日期區間
     * @param {number} index 按鍵索引
     */
    changeData(dayNum, index) {
      this.resetNScroll()

      this.dateBtn = index
      this.setDate(dayNum)
      this.main(this.tab)
    },

    /**
     * 如果成功取得 getSubtypeData
     * @date 2021-03-18
     * @param {object}} data sub_type 相關資訊
     */
    getSubtypeDataSuccess(data) {
      // this.subtypeList = data.redeem_type
      const subtypeData = data.redeem_type
      const textAry = [this.$t('global.all')]
      const codeAry = ['all']

      subtypeData.forEach(item => {
        textAry.push(item.name)
        codeAry.push(item.sub_type)
      })
      this.subTypeList.text = textAry
      this.subTypeList.code = codeAry
    },

    /**
     * 取得 bonus 的 sub_type 列表
     * @date 2021-03-18
     */
    async getSubtypeData() {
      const bonusSubTypeList = await this.get_bonus_subtype()
      resStatus(bonusSubTypeList, this.getSubtypeDataSuccess)
    },

    statusColor(status) {
      const success = ['done', 'complete']
      const pending = ['wait', 'pending', 'verify']
      const fail = ['failed', 'close']

      if (success.includes(status)) return 'success'
      else if (pending.includes(status)) return 'warning'
      else return 'danger'
    },

    setSubtypeActive(subtype) {
      this.subTypeList.active = subtype
      this.dialog.status = false
    },

    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 使用自定義搜尋資料
     * @date 2021-04-28
     */
    customDate() {
      if (this.dateBtn === 4) return false
      this.changeData(0, 4)
    },

    /**
     * 自定義 取得資料方法
     * @date 2021-04-28
     * @param {string} timeRang 時間範圍 '2021-04-01,2021-04-28'
     */
    customDateGetRecord(timeRang) {
      this.form.trans_at = timeRang
      this.main(this.tab)
    },

    resetNScroll() {
      this.$scrollToTop()
      this.resetBtoomSetting()
    },

    /**
     * 重置原始狀態 bottom
     * @date 2021-05-03
     */
    resetBtoomSetting() {
      this.recordData = []
      this.form.page = 1
      this.scrollBottom.status = true
      this.scrollBottom.ing = false
      this.scrollBottom.progress = false
    },

    /**
     * 設置utils 閉包
     * @date 2021-05-03
     * @returns {any}
     */
    setBottomFun() {
      const bottom = scrollBottomFunc({
        form: this.form,
        scrollData: this.scrollBottom,
        func: () => this.main(this.tab),
      })
      return bottom
    },

    /**
     * 底部加載功能
     * @date 2021-05-03
     * @param {any} e
     * @returns {any}
     */
    onScroll(e) {
      if (!isScrollToBottom(e)) {
        return false
      }

      // 已經是最末頁
      if (this.form.page >= this.lastPage) return false

      if (isScrollToBottom(e) && !this.scrollBottom.status) {
        if (this.scrollBottom.ing) return false
        return false
      }

      const bottom = this.setBottomFun()
      bottom.getData()
      setTimeout(() => bottom.reset(), 1000)
    },
  },
}
