<template>
  <!-- 兌獎紀錄 -->
  <div
    id="prize"
    v-scroll.self="onScroll"
    class="pt-56 pb-10 vh-100 overflow-y-auto"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pt-0 fixed bg z-index-1"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            background-color="bg"
            color="primary"
            grow
          >
            <template v-for="(item, index) in tabText">
              <v-tab
                v-show="showPrizeBonus && index === 0 || index !== 0"
                :key="index"
              >
                {{ item }}
              </v-tab>
            </template>
          </v-tabs>

          <!-- 快選日期 -->
          <v-row class="mt-1 px-2 py-2">
            <v-col
              v-for="(item, index) in dataBtnText"
              :key="index"
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
              :class="[
                dateBtn === index
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="changeData(...Object.keys(item), index)"
            >
              {{ item[Object.keys(item)] }}
            </v-col>
            <!-- 自定義 data -->
            <v-col
              class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
              :class="[
                dateBtn === 4
                  ? 'secondary white--text'
                  : 'inputBg subtitle--text',
              ]"
              @click="customDate"
            >
              {{ $t('myCenter.customize') }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        :class="[ dynamicStyleByClientLang ]"
      >
        <v-col
          cols="12"
          class="pb-2 pt-2"
        >
          <!-- 日期選取器 -->
          <Customize
            v-if="dateBtn === 4"
            @get-data="customDateGetRecord"
            @reset-config="resetNScroll"
          />

          <!-- 提醒說明文字 -->
          <p class="caption mb-0">
            {{ $t('myCenter.moreRecordMessage') }}
          </p>
        </v-col>

        <v-col
          v-if="form.type === 'bonus'"
          class="pb-0"
        >
          <!-- 篩選 subtype -->
          <v-btn
            text
            @click="dialog.status = true"
          >
            <!-- FILTER ICON -->
            <v-img
              width="24"
              height="24"
              src="@/assets/icons/filiterIcon.gif"
            />
            <span class="ml-1 title--text">
              {{ subTypeList.active }}
            </span>
          </v-btn>
        </v-col>

        <template v-if="recordData.length === 0">
          <v-col cols="12">
            <Nodata :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <!-- 若有資料 -->
        <template v-else>
          <v-col
            v-for="item in recordData"
            :key="item._id"
            cols="12"
            class="py-1 caption title--text"
          >
            <div class="card1 rounded pa-4 p-relative">
              <!------------------- tab 活動好禮 --------------------->
              <template v-if="tab === 0">
                <!-- 品名 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.prizeName') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.present_content }}
                  </span>
                </div>

                <!-- 類型 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.prizeType') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.present_type }}
                  </span>
                </div>
              </template>
              <!------------------- tab 活動好禮 --------------------->

              <!------------------- tab 紅利 --------------------->
              <template v-else-if="tab === 1">
                <!-- 單號 -->
                <div>
                  <span class="subtitle--text"> {{ $t('myCenter.no') }} </span>
                  <span
                    :id="item.order_id"
                    class="ml-4"
                  >
                    {{ item.order_id }}
                  </span>

                  <!-- copy BTM -->
                  <v-btn
                    icon
                    height="24"
                    width="24"
                    @click="copyNumber(item.order_id)"
                  >
                    <Icon
                      data="@icon/copy.svg"
                      width="16"
                      height="16"
                      class="subtitle--text"
                    />
                  </v-btn>
                </div>

                <!-- 金額 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('global.amount') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.amount }}
                  </span>
                </div>

                <!-- 紅利類型 -->
                <div class="pt-1">
                  <span class="subtitle--text">
                    {{ $t('myCenter.type') }}
                  </span>

                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.sub_type }}
                  </span>
                </div>
              </template>
              <!------------------- tab 紅利 --------------------->

              <!------------------- tab 推薦禮金 --------------------->
              <template v-else>
                <!-- 邀請好友 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedName') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_name }}
                  </span>
                </div>

                <!-- 首存累计存款 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedFirstDeposit') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_first_deposit }}
                  </span>
                </div>

                <!-- 返利奖励 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedRebate') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_rebate }}
                  </span>

                  <!-- 返利狀態 -->
                  <span class="ml-1">({{ item.invited_rebate_status }})</span>
                </div>

                <!-- 邀请礼金 -->
                <div>
                  <span class="subtitle--text">
                    {{ $t('myCenter.invitedRebateStatus') }}
                  </span>
                  <span
                    :id="item.order_no"
                    class="ml-4"
                  >
                    {{ item.invited_bonus }}
                  </span>
                </div>
              </template>
              <!------------------- tab 推薦禮金 --------------------->

              <!-- 日期 -->
              <div class="pt-1">
                <span class="subtitle--text">
                  <Icon
                    data="@icon/date.svg"
                    width="13"
                    height="13"
                  />
                </span>

                <!-- 如果是推薦禮金 tab -->
                <span
                  v-if="form.type === 'recommend'"
                  class="ml-3"
                >
                  {{ $day(item.create_date).format('YYYY/MM/DD') }}
                </span>

                <!-- 如果是 活動獎品 / 紅利 tab -->
                <span
                  v-else
                  class="ml-3"
                >
                  {{ $day(item.trans_at).format('YYYY/MM/DD HH:mm') }}
                </span>
              </div>

              <!-- 狀態 -->
              <v-btn
                outlined
                height="24"
                width="61"
                class="status-btn p-absolute caption"
                :color="statusColor(item.status)"
              >
                {{ $t(`global.${item.status}`) }}
              </v-btn>
            </div>
          </v-col>

          <!--  Bottom LOADING  -->
          <v-col
            v-show="scrollBottom.progress"
            cols="12"
            class="text-center pb-0"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>

    <!-- DIALOG -->
    <Select
      :status="dialog.status"
      :height="344"
      :width="280"
    >
      <v-row
        slot="title"
        class="p-sticky top-0 bg"
      >
        <!-- 選擇紅利類型 -->
        <v-col
          cols="12"
          class="text-h6 title--text px-6 py-4 p-sticky"
        >
          {{ $t('form.missType') }}
        </v-col>
      </v-row>

      <v-row slot="list">
        <v-col
          v-for="(item, index) in subTypeList.text"
          :key="index"
          cols="12"
          :class="['px-6', item === subTypeList.active ? 'secondary' : 'bg', 'rounded']"
          @click="setSubtypeActive(item)"
        >
          {{ item }}
        </v-col>
      </v-row>
    </Select>
  </div>
</template>

<script>
import prizeMixin from '../mixins/prizeMixin'

export default {
  mixins: [prizeMixin],
}
</script>

<style lang="scss" scoped>
.status-btn {
	right: 16px;
	bottom: 16px;
}

.top-0 {
	top: 0;
}

.pt-120{
  padding-top: 120px;
}
</style>
